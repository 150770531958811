<template>
  <div style="overflow-y: auto;height: 100%;position: relative">
    <el-button size="medium" style="width: 72px;padding: 10px 7px;position: absolute;top: 45px;left: 35px" @click="goBack" type="primary"
      >返回</el-button
    >
    <el-button
      size="medium"
      style="padding: 10px 7px;position: absolute;top: 45px;right: 35px"
      :loading="downPDF"
      @click="downPdf()"
      type="primary"
      >{{ title }}</el-button
    >
    <div id="examReport" class="app-examReport">
      <h2 class="re-title">{{ $route.query['examName'] }}</h2>
      <h3 class="re-dec">考试结果分析报告</h3>

      <div class="exam-basis">
        <h3>一、基本信息与排名</h3>
        <div class="dataTable">
          <div class="title">员工考核技能分析</div>
          <div class="content">
            <div class="info">
              <div>总分</div>
              <div>
                <div class="echartBars" id="echartBars"></div>
              </div>
            </div>
            <div class="info">
              <div>人数</div>
              <div>{{ examInfo.peopleNum }}</div>
            </div>
            <div class="info">
              <div>最高分</div>
              <div>{{ examInfo.maxScore }}</div>
            </div>
            <div class="info">
              <div>最低分</div>
              <div>{{ examInfo.minScore }}</div>
            </div>
            <div class="info">
              <div>平均分</div>
              <div>{{ examInfo.avgScore }}</div>
            </div>
            <div class="info">
              <div>时间</div>
              <div>{{ examInfo.submitTime }}</div>
            </div>
          </div>
        </div>
        <div class="examRank">
          <h5>1.考试分数排名</h5>
          <div style="flex-direction: row;flex-wrap: wrap;align-items: flex-start!important;-webkit-box-align: start;">
            <div
              class="tableStyle"
              v-for="(stu, sindex) in stuExamInfo"
              :key="`stu-${sindex}`"
              style="width: 28%;margin-right: 30px;max-height: 330px;"
            >
              <div class="header">
                <div :style="index === 1 ? 'width: 30%;flex-shrink: 0' : ''" v-for="(r, index) in rankListH" :key="`r-${index}`">
                  {{ r.name }}
                </div>
              </div>
              <div v-for="(tu, stuIndex) in stu" :key="`tu-${stuIndex}`">
                <div style="flex-direction: row" class="data" :style="tu.pass === false ? 'color: #F05538' : 'color: #5ABC50'">
                  <div style="margin-right: 3px">{{ tu.rank }}</div>
                  <div style="width: 30%;margin-right: 3px;flex-shrink: 0">
                    <span>{{ tu.realName }}</span>
                  </div>
                  <div style="margin-right: 3px;">
                    <span v-if="tu.jobNumber">{{ tu.jobNumber }}</span>
                    <span v-else style="color: transparent">暂无</span>
                  </div>
                  <div>{{ tu.score }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="examPoint">
          <h5 style="text-align: center">2.考试分数分布</h5>

          <div class="normalDis" style="display: none;">
            <h5 style="justify-content: center;margin-top: 10px">正态分布</h5>
            <div style="width: 100%;height: calc(100% - 30px);" id="normFirst"></div>
          </div>
          <div class="normalDis">
            <h5 style="justify-content: center;margin-top: 10px;">区间分布</h5>
            <div style="width: 100%;height: calc(100% - 30px);" id="normSecond"></div>
          </div>
          <!--            <h5 style="margin-bottom: 10px">由分布图来看:</h5>-->
          <div class="evaDiv eva1" v-show="editorEva === '0'" @click="editorEva = '1'" style="">
            {{ examPointsText.trim() === '' ? '由分布图来看:' : examPointsText }}
          </div>
          <el-input
            v-show="editorEva === '1'"
            style="overflow: hidden;"
            type="textarea"
            autosize
            :row="2"
            v-model="examPointsText"
            @change="svaeEva(examPointsText, 1)"
            @blur="editorEva = '0'"
            placeholder="请设置内容..."
          />
        </div>
      </div>

      <div class="abiliAnalysis">
        <h3>二、技能分析</h3>
        <h5 style="margin-top: 15px;text-indent: 4px;">1.试卷技能掌握排名</h5>
        <div class="abAnalyEchart" id="abAnalyEchart"></div>
        <!--          <h5>由上图分析如下:</h5>-->
        <div class="evaDiv eva2" v-show="editorEva === '0'" @click="editorEva = '2'" style="">
          {{ normText.trim() === '' ? '由上图分析如下:' : normText }}
        </div>
        <el-input
          v-show="editorEva === '2'"
          style="overflow: hidden;"
          type="textarea"
          autosize
          :row="2"
          v-model="normText"
          @change="svaeEva(normText, 2)"
          @blur="editorEva = '0'"
          placeholder="请设置内容..."
        />
      </div>

      <div class="abiliAnalysis">
        <h3>三、考试详细结果</h3>
        <h5 style="margin-top: 15px;text-indent: 4px;">1.人员分数结果</h5>
        <div>
          <el-table border :data="examStuInfo" width="100%">
            <el-table-column label="序号" align="center" prop="index" />
            <el-table-column label="姓名" align="center" prop="userName" />
            <el-table-column label="考试用时" width="150" align="center" prop="duration" />
            <el-table-column label="客观题分数" width="120" align="center" prop="objectiveQuestionScore" />
            <el-table-column label="主观题分数" width="120" align="center" prop="codeQuestionScore" />
            <el-table-column label="总分" width="120" align="center" prop="totalScore" />
          </el-table>
        </div>
        <!--          <h5>由上表分析如下:</h5>-->
        <div class="evaDiv eva3" v-show="editorEva === '0'" @click="editorEva = '3'" style="">
          {{ abilityText.trim() === '' ? '由上表分析如下:' : abilityText }}
        </div>
        <el-input
          v-show="editorEva === '3'"
          style="overflow: hidden;"
          type="textarea"
          autosize
          :row="2"
          v-model="abilityText"
          @change="svaeEva(abilityText, 3)"
          @blur="editorEva = '0'"
          placeholder="请设置内容..."
        />

        <h5 style="margin-top: 15px;text-indent: 4px;">2.技能结果</h5>
        <div>
          <el-table border :data="examAvgRank" width="100%">
            <el-table-column label="序号" align="center" prop="knowledgePointId" />
            <el-table-column label="技能" align="center" prop="knowledgeName" />
            <el-table-column label="主题" width="150" align="center" prop="categoryName" />
            <el-table-column label="平均掌握率" width="120" align="center" prop="averageRate">
              <template slot-scope="scope">
                <div>{{ (scope.row.averageRate * 100).toFixed(2) + '%' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="题目数量" width="120" align="center" prop="questionCount" />
          </el-table>
        </div>
      </div>

      <div class="examEvalu">
        <h3>四、本次考试评价</h3>
        <!--          <h5>请为本场考试做个评价:</h5>-->
        <div class="evaDiv eva4" v-show="editorEva === '0'" @click="editorEva = '4'" style="">
          {{ evalutionText.trim() === '' ? '请为本场考试做个评价:' : evalutionText }}
        </div>
        <el-input
          v-show="editorEva === '4'"
          style="overflow: hidden;"
          type="textarea"
          autosize
          :row="2"
          v-model="evalutionText"
          @change="svaeEva(evalutionText, 4)"
          @blur="editorEva = '0'"
          placeholder="请评价..."
        />
        <div class="tableDate">
          <el-table width="100%" :data="examEvaList">
            <el-table-column align="center" width="120" label="题目序号" prop="questionId" />
            <el-table-column align="left" label="技能" prop="knowledgePoints" />
            <el-table-column align="center" width="120" label="题型" prop="quesType">
              <template slot-scope="scope"
                ><div>{{ scope.row.quesType | getQType }}</div></template
              >
            </el-table-column>
            <el-table-column align="center" width="120" label="正确率" prop="rate">
              <template slot-scope="scope"
                ><div :style="scope.row.rate >= 0.9 ? 'color: green' : scope.row.rate < 0.2 ? 'color: red' : ''">
                  {{ (scope.row.rate * 100).toFixed(2) }}%
                </div></template
              >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import examReport from '../../../../../api/examReport'
import { chunk } from '@util/arrayChunk'
import { normalDisOption, histogrOp, questionTypeBar } from './normalDis'
import { pdfD } from '@util/downPdf'
import { pdfD2 } from '@util/pdfDown2'
import { questionTypeNew } from '@util/questionType'
const defaultH = [{ name: '名次' }, { name: '姓名' }, { name: '工号' }, { name: '总分' }]
const defaultEInfo = {
  peopleNum: null,
  maxScore: 0,
  minScore: 0,
  avgScore: 0,
  submitTime: null
}
export default {
  name: 'examReport',
  data() {
    return {
      editorEva: '0',
      rankListH: Object.assign([], defaultH),
      stuExamInfo: [],
      examInfo: Object.assign({}, defaultEInfo),
      id: 0,
      normOption: { ...normalDisOption },
      histogrOp: { ...histogrOp },
      questionTypeAna: { ...questionTypeBar },
      normText: '',
      examPointsText: '',
      evalutionText: '',
      abilityText: '',
      downPDF: false,
      title: '加载中...',
      examStuInfo: [],
      examAvgRank: [
        {
          knowledgePointId: null,
          knowledgeName: null,
          categoryName: null,
          averageRate: null,
          questionCount: null
        }
      ],
      examEvaList: [],
      colorList: ['#9FBBCF', '#DA2E2E', '#4acacb', '#6382b3', '#E6F986', '#de7f71'],
      explorerInfo: '',
      lowVersion: false
    }
  },
  filters: {
    getQType(type) {
      return questionTypeNew(type)
    }
  },
  created() {
    this.downPDF = true
    this.id = this.$route.query.id
    this.getexamInfo()
    this.getExamEva()
  },
  mounted() {
    this.explorerInfo = navigator.userAgent.toLowerCase().split(' ')
    this.explorerInfo.map(item => {
      if (item.includes('chrome') && item.slice(7).split('.')[0] < 60) {
        document.getElementById('examReport').style = 'display: -webkit-box;'
        this.lowVersion = true
      }
    })
    setTimeout(() => {
      this.downPDF = false
      this.title = 'PDF导出'
    }, 3500)
  },
  methods: {
    goBack() {
      this.$router.push({
        path: '/manage/exam/IndividualPapers',
        query: {
          tabLabel: this.$route.query.tabLabel,
          paperBankId: this.$route.query.paperBankId,
          id: this.$route.query.paperId,
          exam: true,
          name: this.$route.query.name,
          IsExamLibrary: true
        }
      })
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    getExamEva() {
      examReport.getEvaByExamReport(this.$route.query.examId).then(res => {
        let aList = res.res
        aList.forEach(item => {
          switch (String(item.order)) {
            case '1':
              return (this.examPointsText = item.comment || '')
            case '2':
              return (this.normText = item.comment || '')
            case '3':
              return (this.abilityText = item.comment || '')
            case '4':
              return (this.evalutionText = item.comment || '')
            default:
              return ''
          }
        })
      })
    },
    svaeEva(content, order) {
      // if (content.trim() === '') {
      //   this.message('评价不可为空...', 'warning')
      //   return (content = '')
      // }
      let form = {
        comment: content,
        examId: this.id,
        order: order
      }
      examReport.saveEvaByExamReport(form).then(res => {
        if (res.code === 0) {
          this.message('成功', 'success')
          content = res.res
        } else {
          this.message('设置失败', 'warning')
        }
      })
    },
    downPdf() {
      if (this.lowVersion) {
        this.$message.warning('请使用高版本浏览器以使用本功能!')
        return
      }
      if (this.examPointsText.trim() === '') {
        document.querySelector('.eva1').classList.add('IsDisplay')
      }
      if (this.normText.trim() === '') {
        document.querySelector('.eva2').classList.add('IsDisplay')
      }
      if (this.abilityText.trim() === '') {
        document.querySelector('.eva3').classList.add('IsDisplay')
      }
      if (this.evalutionText.trim() === '') {
        document.querySelector('.eva4').classList.add('IsDisplay')
      }
      this.downPDF = true
      this.title = '导出中...'
      pdfD2(document.getElementById('examReport'), '考试结果分析报告.pdf')
      setTimeout(() => {
        this.downPDF = false
        this.title = 'PDF导出'
        let divList = document.querySelectorAll('.evaDiv')
        divList.forEach(item => {
          item.classList.remove('IsDisplay')
        })
      }, 2000)
    },
    getEvaluation() {
      examReport.getExamEvaluation(this.id).then(res => {
        this.examEvaList = res.res
        this.downPDF = false
        this.title = 'PDF导出'
      })
    },
    getIntervalInfo() {
      let payload = {
        departmentIds: this.$route.query.departmentIds,
        examId: this.$route.query.examId,
        tagIds: this.$route.query.tagIds,
        userIds: this.$route.query.userIds
      }
      examReport.getRandomExamPointsByPeoNum(payload, 10).then(res => {
        this.initEchartsF(res.res.normals)
        this.initEchartsS(res.res.statisticsVOS)
      })
    },
    getExamAvgHisRank() {
      examReport.getAvgHisRank(this.id).then(res => {
        this.examAvgRank = res.res
        // this.getExamAvgHisRank()
        this.getEvaluation()
      })
    },
    getExamAvgHisInfo() {
      examReport.getAvgHisRRate(this.id).then(res => {
        this.initHistogram(res.res)
      })
    },
    getStuGrateInfo() {
      let payload = {
        departmentIds: this.$route.query.departmentIds,
        examId: this.$route.query.examId,
        tagIds: this.$route.query.tagIds,
        userIds: this.$route.query.userIds
      }
      examReport.getRandomStuPointResult(payload).then(res => {
        this.examStuInfo = res.res
        this.getExamAvgHisRank()
      })
    },
    getexamInfo() {
      let payload = {
        departmentIds: this.$route.query.departmentIds,
        examId: this.$route.query.examId,
        tagIds: this.$route.query.tagIds,
        userIds: this.$route.query.userIds
      }
      examReport.getRandomNowExamInfo(payload).then(res => {
        this.$nextTick(() => {
          this.examInfo = res.res
          this.initquestionT()
        })
      })
    },
    getSRank() {
      let payload = {
        departmentIds: this.$route.query.departmentIds,
        examId: this.$route.query.examId,
        tagIds: this.$route.query.tagIds,
        userIds: this.$route.query.userIds
      }
      examReport.getRandomStudentsRank(payload).then(res => {
        if (res.res.length > 0) {
          res.res.forEach((r, index) => {
            r.rank = index + 1
          })
          this.stuExamInfo = chunk(res.res, 10)
          this.getIntervalInfo()
        } else {
          this.initEchartsF([])
          this.initEchartsS([])
        }
      })
    },
    initquestionT() {
      let chart = this.$echarts.init(document.getElementById('echartBars'))
      this.questionTypeAna.title.text = `总分:${Number(this.examInfo.examTotalScore).toFixed(0)}分`
      this.questionTypeAna.series[0].data = []
      let len = this.examInfo.sectionInfo.length
      this.examInfo.sectionInfo.forEach((e, index) => {
        this.questionTypeAna.series[0].data.push({
          value: e.percentage ? Number(e.percentage.slice(0, e.percentage.length - 1)) : 0,
          name: e.sectionName,
          itemStyle: {
            color:
              len > this.colorList.length
                ? this.colorList[this.colorList[parseInt(Math.random() * 6)]] // 防止数组越界
                : this.colorList[index]
          }
        })
      })
      chart.setOption(this.questionTypeAna)
      this.getSRank()
    },
    initEchartsF(norData) {
      let xMax = 0
      let yMax = 0
      let mychart = this.$echarts.init(document.getElementById('normFirst'))
      this.normOption.legend.data = ['区间频次', '正态曲线']
      let x = this.normOption.xAxis[0]
      let y = this.normOption.yAxis[0]
      let y2 = this.normOption.yAxis[1]
      this.normOption.series = [
        {
          name: '区间频次',
          type: 'bar',
          barWidth: 22, // 柱条的宽度
          data: [],
          itemStyle: {
            normal: {
              label: { show: false, formatter: '{c}%' },
              color: '#218ACE'
            }
          }
        },
        {
          name: '正态曲线',
          type: 'line',
          yAxisIndex: 1,
          itemStyle: {
            normal: {
              label: { show: true },
              color: '#218ACE'
            }
          },
          smooth: true, // 是否设置平滑曲线
          data: []
        }
      ]
      let a = this.normOption.series[0].data
      let b = this.normOption.series[1].data
      for (let i = 0; i < norData.length; i++) {
        if (norData[i].x > xMax) {
          xMax = norData[i].x
        }
        if (norData[i].y > yMax) {
          yMax = norData[i].y
        }
      }
      x.data = this.getResult(norData.length)
      y.max = xMax + 10
      y.min = 0
      y.interval = 10
      y.axisLabel.formatter = '{value}%'
      y2.max = (yMax + 0.01).toFixed(2)
      y2.min = 0
      y2.interval = 0.005
      y.axisLabel.formatter = '{value}%'
      for (let i = 0; i < norData.length; i++) {
        a.push(norData[i].x)
        b.push(norData[i].y)
      }
      mychart.setOption(this.normOption)
    },
    initEchartsS(statisticsVOS) {
      let xMax = 0
      let yMax = 0
      let mychart = this.$echarts.init(document.getElementById('normSecond'))
      for (let i = 0; i < statisticsVOS.length; i++) {
        if (statisticsVOS[i].num > xMax) {
          xMax = statisticsVOS[i].num
        }
        if (statisticsVOS[i].frequency > yMax) {
          yMax = statisticsVOS[i].frequency
        }
      }
      let x = (this.normOption.xAxis[0].data = [])
      let y = this.normOption.yAxis[0]
      let y2 = this.normOption.yAxis[1]
      this.normOption.legend.data = ['频数', '频率']
      this.normOption.series = [
        {
          name: '频数',
          type: 'bar',
          barWidth: 22, // 柱条的宽度
          data: [],
          itemStyle: {
            normal: {
              label: { show: true, formatter: '{c}' },
              color: '#218ACE'
            }
          }
        },
        {
          name: '频率',
          type: 'line',
          yAxisIndex: 1,
          label: {
            show: false,
            position: 'right'
          },
          itemStyle: {
            color: '#E67E36'
          },
          tooltip: {
            show: true,
            formatter: params => {
              return params.data.toFixed(2) + '%'
            }
          },
          smooth: true, // 是否设置平滑曲线
          data: []
        }
      ]
      let a = this.normOption.series[0].data
      let b = this.normOption.series[1].data
      y.max = xMax + 5
      y.min = 0
      y.interval = xMax > 20 ? 10 : 1
      y.axisLabel.formatter = '{value}'
      y2.max = (yMax * 100 + 10).toFixed(2)
      y2.min = 0
      y2.interval = 10
      y2.axisLabel.formatter = '{value}%'
      for (let i = 0; i < statisticsVOS.length; i++) {
        x.push(statisticsVOS[i].space)
        a.push(statisticsVOS[i].num)
        b.push(statisticsVOS[i].frequency * 100)
      }
      mychart.setOption(this.normOption)
      this.getExamAvgHisInfo()
    },
    getResult(a) {
      let b = []
      for (let i = 0; i < a; i++) {
        b.push(i * 10)
      }
      return b
    },
    initHistogram(data) {
      let mychart = this.$echarts.init(document.getElementById('abAnalyEchart'))
      let a = []
      let b = []
      data.forEach(d => {
        a.push(d.knowledgeName)
        b.push((d.averageRate * 100).toFixed(2))
      })
      this.histogrOp.yAxis.data = a
      this.histogrOp.series[0] = {
        name: '平均掌握度',
        type: 'bar',
        data: b,
        itemStyle: {
          color: '#006EC7'
        },
        label: {
          show: true,
          position: 'right',
          color: '#fff',
          formatter: '{c} %'
        }
      }
      mychart.setOption(this.histogrOp)
      this.getStuGrateInfo()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/examReport';
.IsDisplay {
  display: none;
}
</style>
